import { AccountStorage } from "@services/account";
var accountStorage = new AccountStorage();
export default {
  name: "",
  data: function data() {
    return {
      url: ""
    };
  },
  created: function created() {
    var ENV_APP = process.env.VUE_APP_API_SERVER;
    var clientCode = accountStorage.getCacheUserInfo().account;
    if (ENV_APP == "https://api-uat.pujian.com") {
      this.url = "https://pay-uat.pujian.com/#/Reconciliation?clientCode=" + clientCode + "&orderSource=5&cacheId=0";
    } else if (ENV_APP == "https://api-pre.pujian.com") {
      this.url = "https://pay-pre.pujian.com/#/Reconciliation?clientCode=" + clientCode + "&orderSource=5&cacheId=0";
    } else if (ENV_APP == "https://api.pujian.com") {
      this.url = "https://pay.pujian.com/#/Reconciliation?clientCode=" + clientCode + "&orderSource=5&cacheId=0";
    }
  }
};