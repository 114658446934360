var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "height": "100%",
      "background-color": "#ffffff"
    }
  }, [_c('iframe', {
    ref: "iframe",
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0px",
      "margin": "0"
    },
    attrs: {
      "id": "frame",
      "src": _vm.url,
      "frameborder": "0"
    }
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };